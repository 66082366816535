export const AppRoute = {
    HomePage:"/",
    UberUns:"/Über-uns",
    Gruppen:"/Gruppen",
    Blog:"/Blog",
    Startseite:"/Startseite",
    Preisübersicht:"/Preisübersicht",
    Mitgliedwerden:"/Mitgliedwerden",
    Anmelden:"/Anmelden",
    Page404:"/Page404"

}